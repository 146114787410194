/* CSS Document */

.cupping-container {
    display: grid;
    grid-template-columns: max-content auto;
    float: left;

}

.cupping-sidenav {
    height: 100%;
    width: 350px;
    background-color: white;
    border-right-style: solid;
    border-right-color: #F3F1F1;
    border-right-width: 2px;
    overflow-x: hidden;
    padding-top: 10px;
    padding-left: 20px;
}

.cupping-content {
    padding: 10px 0px 0px 12px;
    height: 100%;
    width: 100%;
    text-align: left;
    margin-left: 0px;
    float:left;
    margin-left:0px;
}

.cupping-content-form {
    background-color: #f2f2f2;
}

.cuppingCreatable {
    max-width: 450px;
    min-width: 300px;
}

.cuppingSlider {
    width: 200px;
}

.cupping-inputs select {
    border: 1px solid lightgrey;
    border-radius: 4px;
    float: left;
    vertical-align: middle;
    height: 43px;
    padding-left: 6px;
    font-family: roboto, sans-serif;
    font-size: 15px;
    line-height: 1.5;
    color: gray;
    width: 150px;
}

    @media screen and (max-width: 600px) {
        .cupping-container {
            grid-template-columns: auto;
        }

        .cupping-sidenav {
            width: 100%;
            border-right: none;
            border-bottom: 2px solid #F3F1F1;
            padding-bottom: 20px;
        }
    }
