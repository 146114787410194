/* CSS Document */

.faq-container {
    display: grid;
    grid-template-columns: auto auto;
    float: left;
}

.faq-sidenav {
    height: 100%;
    width: 300px;
    background-color: white;
    border-right-style: solid;
    border-right-color: #F3F1F1;
    border-right-width: 2px;
    overflow-x: hidden;
    padding-top: 10px;
}

.faq-sidenav a {
    padding: 6px 6px 6px 32px;
    text-decoration: none;
    display: block;
}

.faq-sidenav a:hover {
    color: #81A079;
    cursor: pointer;
    font-weight: bolder;
}

.faq-sidenav a.active {
    font-weight: bolder;
    border-left: 3px solid black;
    background-color: white;
}

.faq-selectbar {
    height: 30px;
    width: 100%;
    background: #ddd;
    display: none;
}

.faq-selectbar option {
    background: white;
}

.faq-content {
    padding: 10px 0px 0px 20px;
    height: 100%;
    text-align: left;
    max-width: 800px;
}

@media screen and (max-width: 600px) {
    .faq-container {
        grid-template-columns: auto;     
    }
   
    .faq-sidenav {
        display: none;
    }

    .faq-selectbar {
        display: block;
    }

}