/* Style the button that is used to open and close the collapsible content */
.collapsible {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 6px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
 
  
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active, .collapsible:hover {
  background-color: #ccc;
}

/* Style the collapsible content. Note: hidden by default */
.content {
  padding: 6px 0px 0px 6px;
  /*display: none;*/
  overflow: hidden;
  background-color: #f1f1f1;
  width: 100%;
  cursor: pointer;

}

.colapsibleContentItem {
    border-bottom: 2px solid white;
 
}

.collapsible-title {
    background-color: white;
    padding: 12px;
    border: 0.5px solid lightgray;
}

.collapsible-list {
    padding-left: 0px;
    margin-left: 0px;
   
}

