/* CSS Document */
/* Order Table*/

.myorders-table {
    border-collapse: collapse;
    width: 100%;
}

.myorders-table-container {
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: white;
    text-align: left;
    border: 0.5px solid #ddd;
    padding: 0px 0px 0px 0px;
}

.myorders-table td {
    border-bottom: 0.5px solid #ddd;
    padding: 12px;
    font-size: 15px;
    max-width: 200px;
    vertical-align: middle;
}

.myorders-table th {
    border-bottom: none;
    font-size: 15px;
    vertical-align: middle;
    background-color: #f2f2f2;
    color: black;
    padding: 12px;
}

.myorders-tabletitle {
    padding: 0px;
    margin: 0px;
    font-size: 18px;
}

.myorders-tabletitle2 {
    padding: 0px;
    margin: 0px;
    font-size: 15px;
}

