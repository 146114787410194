/* CSS Document */


/*Still using with material ui */

/* background and font colors */

.backgroundGreen {background-color: #9FAC99;}

.fontColorWhite { color: white;}
.fontColorYellow { color: #D0C34C;}
.fontColorGreen {    color: #556b2f;}

/* used to set display flex in cards and change it back to block in small screen*/
.displayFlexBlock { display: flex,}

/* used to set picture as background*/
.homeContainer {
    position: relative;
    text-align: left;
    color: white;
    padding-bottom: 0px;
    margin-bottom: 0px;
    background: none;
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.homepicture {
    width: 100%;
    object-fit: cover;
    opacity: 1;
    height: 580px;
}



@media only screen and (max-width: 600px) {
    .displayFlexBlock {
        display: block,
    }
}


    /*END OF Still using with material ui */


    /* Container holding the image and the text */




    /* Centered text relative to picture */


    .centered2 {
        position: absolute;
        top: 20%;
        left: 3%;
        max-width: 550px;
    }

    .homeCircle {
        position: absolute;
        top: 55%;
        left: 80%;
        height: 230px;
        width: 230px;
        background-color: white;
        border-radius: 50%;
        text-align: center;
        padding: 25px 25px 15px 25px;
        color: black;
        cursor: pointer;
    }




    /* usar junto com homeConatiner para separar conteúdo na vertical */
    .margintopconatiner {
        margin-top: 30px;
    }


    .whatwedocontainer {
        border: none;
        background-color: none;
    }

    .whatwedocontainer2 {
        border: none;
        background-color: #796454;
        padding-top: 30px;
        padding-right: 10px;
        padding-bottom: 10px;
        padding-left: 20%;
        margin-top: 20px;
        min-height: 350px;
    }

    .whatwedocontainer3 {
        border: none;
        background-color: #F3F1F1;
        padding-top: 30px;
        padding-bottom: 10px;
        padding-right: 10px;
        margin-top: 30px;
        min-height: 420px;
    }

    .contactcontainer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 70%;
        max-width: 800px;
        border: none;
        background-color: #F3F1F1;
        padding: 30px;
        margin: 0;
        color: black;
    }

    .sectionContainer {
        margin-top: 30px;
        border: none;
        background-color: none;
        min-height: 500px;
        color: black;
        top: 50%;
    }

    .sectionContainer-buttons {
        display: inline-block;
        padding: 20px 0 20px 0;
        float: none;
        vertical-align: middle;
    }

    .circleContainer {
        height: 150px;
        width: 150px;
        background-color: white;
        border-radius: 50%;
        text-align: center;
        padding-top: 25px 25px 15px 25px;
        color: gray;
        display: inline-block;
    }


    .getStartedForm {
        padding-top: 12px;
        vertical-align: middle;
    }

        .getStartedForm input {
            width: 50%;
            border: thin;
            border-color: black;
            line-height: 2.5;
            background-color: white;
            margin: 1%;
        }

            .getStartedForm input[type="button"] {
                width: 50%;
                border: none;
                line-height: 2.5;
                background-color: yellow; /*#D0C34C;*/
                margin: 1%;
                text-align: center;
            }

    /* background colors */

    .backgroundGray {
        background-color: whitesmoke;
        padding: 10px 0 10px 0;
    }

    .backgroundBlack {
        background-color: black;
        padding: 0px;
    }

    .backgroundBrown {
        background-color: #796454;
        padding: 0px;
    }

    .backgroundWhite {
        background-color: white;
        padding: 0px;
    }


    /* pictures */

    .homeforGrowersPicture {
        width: 100%;
        object-fit: cover;
        opacity: 1;
        height: 580px;
    }


    .contactpicture {
        width: 100%;
        object-fit: cover;
        opacity: 1;
        height: 600px;
    }

    .contactTextPicture {
        width: 100%;
        object-fit: contain;
        opacity: 1;
    }

    .bannerPictureLeft {
        width: 100%;
        object-fit: contain;
        opacity: 1;
        height: 450px;
    }


    .bannerTextRight {
        min-width: 410px;
    }

    /* texts and fonts */

    .texts {
        text-align: left;
        padding-left: 15%;
        padding-right: 15%;
        padding-bottom: 5px;
        border: none;
    }

    .textsnarrow {
        text-align: left;
        padding-left: 5%;
        padding-right: 5%;
        padding-bottom: 5px;
        border: none;
    }


    .headline {
        font-family: "roboto", sans-serif;
        color: white;
        font-size: 60px;
        line-height: 1.0;
        margin-top: 0px;
        margin-bottom: 20px;
        letter-spacing: 3px;
    }

    .headline2 {
        font-family: "roboto", sans-serif;
        color: white;
        font-size: 40px;
        line-height: 1.2;
        margin-top: 30px;
        margin-bottom: 6px;
    }

    .headline3 {
        font-family: "roboto", sans-serif;
        color: white;
        font-size: 20px;
        line-height: 1.2;
        margin-top: 30px;
        margin-bottom: 6px;
    }

    .blocktitle {
        font-family: "roboto light", sans-serif;
        color: #D0C34C;
        font-size: 25px;
        line-height: 1.5;
        letter-spacing: 4px;
        margin-top: 12px;
        margin-bottom: 0px;
        font-weight: bolder;
        text-transform: uppercase;
    }

    .blocktitle2 {
        font-family: "roboto light", sans-serif;
        color: #D0C34C;
        font-size: 20px;
        line-height: 1.5;
        letter-spacing: 4px;
        margin-top: 12px;
        margin-bottom: 0px;
        font-weight: bolder;
    }


    .pagetitle {
        font-family: "roboto light", sans-serif;
        color: black;
        font-size: 20px;
        letter-spacing: 2px;
        margin-top: 15px;
        margin-bottom: 0px;
        font-weight: bolder;
        padding: 0px;
    }

    .pagetitle2 {
        font-family: "roboto light", sans-serif;
        color: black;
        font-size: 15px;
        letter-spacing: 2px;
        margin-top: 15px;
        margin-bottom: 0px;
        font-weight: bolder;
        padding: 0px;
    }

    .pagetitle3 {
        font-family: "roboto light", sans-serif;
        color: black;
        font-size: 13px;
        letter-spacing: 1.5px;
        margin-top: 15px;
        margin-bottom: 0px;
        padding: 0px;
    }

    .detailtext {
        font-family: roboto, sans-serif;
        font-size: 12px;
        color: #B4B4B4;
        line-height: 1.5;
    }

    /*
h1 {
    font-family: "roboto light", sans-serif;
    color:  #7E9373;
    font-size: 20px;
    line-height: 1.1;
}

h2 {
    font-family: "roboto light",  sans-serif;
    color:  #7E9373;
    font-size: 18px;
    line-height: 1.3;
}

h3 {
	font-family: "roboto light",  sans-serif;
    color:  #7E9373;
    font-size: 15px;
    line-height: 1.5;
}
*/

    .fontColorGreen {
        color: darkolivegreen;
    }

    .fontColorBlack {
        color: black;
    }

    .fontColorGrey {
        color: gray;
    }

    /* take format from link*/

    .noDecoration {
        text-decoration: none;
        color: black;
    }

    p, li {
        font-family: roboto, sans-serif;
        font-size: 15px;
        line-height: 1.5;
    }

    ul .a {
        list-style-type: circle;
    }

    .centertextalign {
        text-align: center;
    }

    .lefttextalign {
        text-align: left;
    }

    .righttextalign {
        text-align: right;
    }

    .floatRight {
        float: right;
    }

    .noMarginPadding {
        margin-top: 0px;
        padding-top: 0px;
    }

    .singleSpaceLine {
        line-height: 1;
    }

    /* list with bullets */

    .listWhithBullets li {
        list-style: disc;
    }

    .listInside li {
        list-style-position: inside;
    }



    /*
    Changing collor of material design icons
*/
    .mdPointer {
        cursor: pointer;
    }

    .mdRed {
        color: red;
    }

    .mdGreen {
        color: #9FAC99;
    }

    .mdGreen2 {
        background-color: #9FAC99;
    }

    .mdAlign {
        vertical-align: text-bottom;
        margin-right: 5px;
        margin-left: 5px;
    }


    /*
Added this for contract card formatting
*/
    .card {
        /* Add shadows to create the "card" effect */
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        transition: 0.3s;
    }

        /* On mouse-over, add a deeper shadow */
        .card:hover {
            box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
        }

    /* Add some padding inside the card container */
    /*.container {
    padding: 2px 16px;
}*/



    /* responsive to phone */


    @media only screen and (max-width: 800px) {
        .texts {
            padding-left: 30px;
        }

        .headline, .headline2, .pagetitle, h1, h2 {
            font-size: 18px;
        }

        .headline3 {
            font-size: 15px;
        }
    }

    @media only screen and (max-width: 600px) {
        .headline, .headline2, .headline3 {
            text-align: left;
            margin-top: 2px;
            margin-bottom: 2px;
            letter-spacing: normal;
        }

        .headline, .headline2, .pagetitle, h1, h2 {
            font-size: 18px;
        }

        .headline3 {
            font-size: 15px;
        }


        .homeCircle {
            display: none;
        }

        .homepicture {
            height: 300px;
        }

        .homeforGrowersPicture {
            opacity: .2;
            height: 350px;
        }

        .getStartedForm input, input[type="button"] {
            line-height: 1.5;
        }

        .whatwedocontainer2 {
            padding-left: 10px;
            margin-top: 0px;
            min-height: 10px;
        }

        .texts {
            padding-left: 30px;
        }
    }
