/* CSS Document */
.confirmInfo_container {
    background-color: #f2f2f2;
    color: black;
	padding-bottom: 12px;
	padding-top: 12px;
	margin-top: 20px;
	padding: 12px;
	margin-right: 10%;
}





/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */

@media screen and (max-width: 600px) {
	.confirmInfo_container {
	margin-left: 5%;
}
	
}
