/* CSS Document */

.basic-table {
    border-collapse: collapse;
     width: 100%;
}

.basic-table-container {
	padding-left: 5%;
	padding-right: 5%;
	padding-bottom: 5px;
	padding-top: 20px;
}

.basic-table-container2 {
    padding-left: 5%;
    padding-right: 22.5%;
    padding-bottom: 5px;
    padding-top: 20px;
}

.basic-table td, .basic-table th {
    border-bottom-style: solid;
    border-bottom-width: thin;
    border-bottom-color: lightgrey;
    padding: 12px;
    font-size: 15px;
    max-width: 200px;
    vertical-align: middle;
}

/* "zebra" effect */
/*.basic-table tr:nth-child(even){background-color: #f2f2f2;}*/


.basic-table tr:hover {background-color: #ddd;}

.basic-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #9FAC99;
    color: white;
}

/* Order Table*/


.order-table {
    border-collapse: collapse;
    width: 100%;
}

.order-table-container {
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 5px;
    padding-top: 20px;
    background-color: #f2f2f2;
}

.order-table td, .order-table th {
    border: .3px;
    border-bottom: solid #ddd;
    padding: 12px;
    font-size: 15px;
    max-width: 200px;
    vertical-align: middle;
}

.order-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #9FAC99;
    color: white;
}
