/*@charset 'UTF-8';
*/

/* Container grower's picture and infobox */
.growerContainer {
   	position: relative;
    text-align: left;
    color: white;
	margin-bottom: 12px;
	
	
	
}

/* position and style for infobox */
.infobox {
    height: 500px;
	position: absolute;
    top: 40%;
    left: 70%;
    transform: translate(-50%, -50%);
	max-width: 350px;
	padding-top: 24px;
	padding-bottom: 24px;
	padding-left: 12px;
	padding-right: 12px;
	background-color: #9FAC99;
}

.backIcon {
	height: 40px;
	object-fit: contain;
	display:block;
	float: left;
	margin-top: 6px;
	padding-right: 100%;
	
}

.horizontalpic {
	width: 100%;
    height: 500px;
	object-fit: cover;
	background: white;
	padding-top: 30px;

}

.grower-page table {
    text-align: left;
	vertical-align: text-top;
	line-height: 2;
}


.grower-page th, td {
    text-align: left;
	vertical-align: text-top;
	border-bottom: 1px solid #ddd;
	
}

.grower-page ul.a {
    list-style-type: circle;
}


/* responsive */
@media only screen and (max-width: 400px) {
    .infobox {
    height: 400px;
	top: 46%;
	}
}

