/* CSS Document */

.basicforms {
    box-sizing: border-box;
	font-size: 16px;
	line-height:1.5;
	text-align: left;
	}

/* Clear floats after the columns */
.row::after {
    content: "";
    display: table;
    clear: both;
}

[class*="col-"] {
    float: left;
	text-align: left;
    margin-top: 6px;
	margin-bottom: 6px;
	border-bottom: 1px solid #ddd;
	padding-bottom: 3px;
	vertical-align: top;
	border:none;
}

.col-01 {width: 1%;}
.col-05 {width: 5%;}
.col-10 {width: 10%;}
.col-20 {width: 20%;}
.col-25 {width: 25%;}
.col-30 {width: 30%;}
.col-33 {width: 33%;}
.col-40 {width: 40%;}
.col-45 {width: 45%;}
.col-48 {width: 48%;}
.col-50 {width: 50%;}
.col-60 {width: 60%;}
.col-65 {width: 65%;}
.col-67 {width: 67%;}
.col-70 {width: 70%;}
.col-75 {width: 75%;}
.col-80 {width: 80%;}
.col-90 {width: 90%;}
.col-100 {width: 100%;}

.basicforms input[type=text], input[type=number], input[type=email], input[type=list], input[type=password], select, option, textarea {
    border: none;
    float: left;
    width: 99%;
    vertical-align: middle;
    line-height: 1.5;
    height: auto;
}


.basicforms input[type=submit] {
    background-color: #9FAC99;
    color: white;
    padding: 12px 20px;
    border: none;
    cursor: pointer;
    float: right;
    margin-right: 3px;
}

.basicforms input[type=submit]:hover {
    background-color: #81A079;
}

.basicforms li:hover {
	color: #81A079;
}

.basicforms input[type=button] {
    background-color: #9FAC99;
    color: white;
    padding: 12px 20px;
    border: none;
    cursor: pointer;
    float: right;
    margin-right: 3px;
}

.basicforms input[type=button]:hover {
    background-color: #81A079;
}

.basicformsSmall input[type=button] {
    background-color: none;
    color: black;
    padding: 6px 10px;
    border: none;
    cursor: pointer;
    float: right;

}

.basicformsSmall input[type=button]:hover {
     background-color: #81A079;
}

.onesize-button input[type=button] {
    height: 40px;
    width: 120px;
    margin-right: 10px;
}

.onesize-button2 input[type=button] {
    height: 40px;
    width: 120px;
    margin-right: 10px;
    background-color: white;
    color: gray;
    padding: 12px 20px;
    border: 1px solid gray;
    cursor: pointer;
}




.form_container {
    background-color: #f2f2f2;
    color: black;
	padding-bottom: 12px;
	padding-top: 12px;
	padding-left: 20%;
	padding-right: 20%;
}

.form_containerNarrow {
    padding-left: 30%;
    padding-right: 30%;
}

.form_padding30 {
    padding-left: 30px;
    padding-right: 30px;
}

.item-remove {
    color: #aaa;
    float: right;
    /*font-size: 28px;*/
    font-weight: bold;
}
.item-remove:hover,
.item-remove:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}


/* grids */

.grid-container {
    display: grid;
    grid-column-gap: 30px;
    grid-row-gap: 15px;
    
}
.grid-container-padding {
    display: grid;
    grid-column-gap: 30px;
    grid-row-gap: 15px;
    padding: 12px;
}

.grid-1colum {    grid-template-columns: auto;}
.grid-2colums {grid-template-columns: max-content auto;}
.grid-2columsAA {grid-template-columns: auto auto;}
.grid-2columsAM {grid-template-columns: auto max-content;}
.grid-title {grid-template-columns: auto max-content; padding-bottom: 12px;}
.grid-3colums {grid-template-columns: max-content max-content auto;}
.grid-3columsMAM {grid-template-columns: max-content auto max-content;}
.grid-4colums {grid-template-columns: max-content max-content max-content auto;}

.floatleft {
    float: left;
}

.floatright {
    float: right;
}


/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    [class*="col-"] {
        width: 100%;
        margin-top: 0;
    }



    .grid-2colums, .grid-2columsAM, grid-3colums, .grid-3columsMAM, .grid-4colums {
        display: grid;
        grid-template-columns: auto;
        text-align: left;
    }
}
