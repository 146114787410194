@charset "utf-8";
/* CSS Document */

:root {
    --reportWidth-bg-color: 80%;
}

.widthCuppingReport {
    width: 100%
}

.report-grid-container {
    display: grid;
    grid-column-gap: 30px;
    grid-row-gap: 5px;
    vertical-align: top;
}

/*

.report-card {
    margin-right: 15px;
    background-color: white;
    padding: 0 5px 5px 5px;
    border-radius: 15px;
    min-height: 200px; 
 
}

.report-card-big {
    min-height: 505px;
}

*/


.report-card {
    border-bottom: 1px solid lightgrey;
    margin-right: 10px;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.report-colum1 { width: 200px;}
.report-colum2 { width: 180px;}


.report-bar {
    background-color: white;
    height: 60px;
    width: 200px;
    margin-right: 10px;
    padding: 0 0 0 0;
}

.intensity-bar {
      width: 80px;
      padding: 4px 0px 0px 0px;
}

.report-dataMargin {
    margin-top: 10px;
}

.report-observation{
    max-width: 600px;
}

[class*="score-"] {
    text-align: center;
    background-color: lightblue;
    height: 30px;
    margin-left: 0;
}

.score-000 {width: 0%;}
.score-025 {width: 2.5%;}
.score-050 {width: 5%;}
.score-075 {width: 5.75%;}
.score-100 {width: 10%;}
.score-125 {width: 12.5%;}
.score-150 {width: 15%;}
.score-175 {width: 15.75%;}
.score-200 {width: 20%;}
.score-225 {width: 22.5%;}
.score-250 {width: 25%;}
.score-275 {width: 25.75%;}
.score-300 {width: 30%;}
.score-325 {width: 32.5%;}
.score-350 {width: 35%;}
.score-375 {width: 35.75%;}
.score-400 {width: 40%;}
.score-425 {width: 42.5%;}
.score-450 {width: 45%;}
.score-475 {width: 45.75%;}
.score-500 {width: 50%;}
.score-525 {width: 52.5%;}
.score-550 {width: 55%;}
.score-575 {width: 55.75%;}
.score-600 {width: 60%;}
.score-625 {width: 62.5%;}
.score-650 {width: 65%;}
.score-675 {width: 65.75%;}
.score-700 {width: 70%;}
.score-725 {width: 72.5%;}
.score-750 {width: 75%;}
.score-775 {width: 75.75%;}
.score-800 {width: 80%;}
.score-825 {width: 82.5%;}
.score-850 {width: 85%;}
.score-875 {width: 85.75%;}
.score-900 {width: 90%;}
.score-925 {width: 92.5%;}
.score-950 {width: 95%;}
.score-975 {width: 95.75%;}
.score-1000 {width: 100%;}

[class*="totalscore-"] {
    text-align: center;
    background-color: lightblue;
    height: 30px;
    margin-left: 0;
}

[class*="totalscore-6"] {width: 60%;}
[class*="totalscore-7"] {width: 70%;}
[class*="totalscore-8"] {width: 80%;}
[class*="totalscore-9"] {width: 90%;}
[class*="totalscore-10"] {width: 100%;}