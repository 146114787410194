/*Format navbar*/
nav {
  background-color: white;
  border-style: solid;
  border-color: #F3F1F1;
  border-width: 1px;

}
ul {
    list-style-type: none;
    margin: 0;
    overflow: hidden;
	padding-right: 30px;
	padding-left: 20px;

}

nav li {
    float: right;
}

li a, .dropbtn {
    display: inline-block;
    color: black;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
	
}

.navDivider {
    border-left-style: solid;
    border-left-width: thin;
    border-left-color: lightgrey;
    
}

nav a:hover, .dropdown:hover, .dropbtn:hover {
    background-color: none;
	color: #81A079;
}

nav a.active {
	color: #81A079;
}

li.dropdown {
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

.dropdown-content a:hover {background-color: #f1f1f1;}

.dropdown:hover .dropdown-content {
    display: block;
}

.nav-item-right {
  float: right;
}

.nav-item-left {
  float: left;
}

nav input[type=submit]:hover, nav input[type=button]:hover  {
    color: #81A079;
}

nav input[type=button], nav input[type=submit] {
    background-color: white;
    border-style: groove;
    color: black;
    display: inline-block;
    text-align: center;
    padding: 5px 35px;
    text-decoration: none;
    font-weight: bolder;
}

.logonav {
	height: 30px;
	object-fit: contain;
	float: left;
	margin-left: 15px;
	margin-top: 10px;
}

.fontlogo {
	font-family: "roboto light", sans-serif;
	font-weight: bolder;
	font-size: 30px;
	letter-spacing: 3px;
	line-height: 10px;
	padding-top: 11px;
}

.fontnav {
	padding-top: 8px;
	font-size: 15px;
	
}

.signoutbutton {
	background-color: white;
    border-style: groove;
    color: black;
    display: inline-block;
    text-align: center;
    padding: 5px 35px;
    text-decoration: none;
}

/* responsive to phone */

@media screen and (max-width: 600px) {

	.fontlogo {
		font-size: 25px;
	}

	.fontlogo, .fontnav {
		display: block;
		padding: 0px;
		margin: 0px;
		float: left;
		line-height: 8px;
	}

    nav input[type=button], nav input[type=submit], ul, li a, .dropbtn, li.dropdown {
        display: block;
        float: none;
    }



    .navDivider {
        border-left: hidden;
      
    }
}
	
/*
Responsive styles


.menu-icon {
  display: none;
}

.menu-icon:hover {
  cursor: pointer;
}


/* When the screen is less than 600 pixels wide, hide all links, except for the first one ("Home"). Show the link that contains should open and close the topnav (.icon) */
/*
@media screen and (max-width: 600px) {
  nav li:not(:first-child) {display: none;}
  nav .menu-icon {
    float: left;
    display: inline-block;
  }
  */
/*
  .logonav {
    display: none
  }

}
*/
/* The "responsive" class is added to the topnav with JavaScript when the user clicks on the icon. This class makes the topnav look good on small screens (display the links vertically instead of horizontally) */
/*
@media screen and (max-width: 600px) {
  nav .responsive {
    position: relative;
    width: 100%;
  }
  .responsive .menu-icon {
    float: left;
  }
  nav .responsive li {
    float: none;
    display: block;
    text-align: left;
  }
  nav::after {
    clear: both;

  }
}
*/
/*
End responsive styles
*/
