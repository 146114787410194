.App {
  text-align: left;
	
}

.App-logo {
	{/*animation: App-logo-spin infinite 20s linear;*/}
    width: 150px;
    height: 45px;
	object-fit: contain;
	float: left;
}

.App-header {
  background-color: whitesmoke;
   padding-top: 10px;
  color: black;
}

.App-title {
  font-size: 1.3em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}






