@charset "utf-8";
/* CSS Document */

* {
    box-sizing: border-box;	
}


div.growersgallery {
	padding-left: 14%;
	padding-right: 0px;
	
}

div.gallery {
    border: 1px solid white;
	padding: 10px 10px 10px 10px;
	margin-right: 20px;
}

div.gallery:hover {
    border: 1px solid #E9E9E9;
	padding: 10px 10px 10px 10px;
	
	
}

div.gallery img {
    width: 100%;
    height: 280px;
	min-width: 250px;
	max-width: 320px;
	object-fit: cover;
}

.descgallery {
    text-align: center;
	min-width: 250px;
	max-width: 320px;
	padding-top: 0px;
	display:block;
}


.responsive {
    padding: 0px;
	float: left;
    width: 22%;
}

.clearfix:after {
    content: "";
    display: table;
    clear: both;
}

@media only screen and (max-width: 1400px) {
    .responsive {
        width: 30%;
        
    }
}

@media only screen and (max-width: 1100px) {
    .responsive {
        width: 43%;
        
    }
}


@media only screen and (max-width: 800px) {
    .responsive {
        width: 100%;
    }
	
	div.growersgallery {
		padding-top: 12px;
		padding-left: 12px;
		padding-right: 0px;
	}
	
}





